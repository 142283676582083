/*----------------------------------------------------------*\
    Basestyles

    Base styles that are too small for individual files. The
    animate classes listed here will be the default for
    animated elements unless overriden.

\*----------------------------------------------------------*/

body {
	background: $white;
	font-family: $main-font;
	font-size: 18px;
	font-weight: $main-light;
	color: $main-color;
	padding-top: 69px;
	padding-bottom: 55px;
	position: relative;
	@include breakpoint(650px) {
		padding-top: 97px;
	}
}
p {
	font-size: 1em;
	line-height: 1.5;
	margin: 0 0 24px;
	&.intro {
		font-size: rem-calc(18);
	}
}
a {
	color: $link-base;
	text-decoration: none;
	transition: all .3s;
	&:hover,
	&:focus {
		color: $link-active;
		text-decoration: none;
	}
}
.page-content a {
	text-decoration: underline;
}
hr {
	margin: 20px 0;
}
/* @end */