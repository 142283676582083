/*------------------------------------*\
    @group Hero
\*------------------------------------*/
.hero {
  @include xy-grid-container;
  padding-top: 30px;
}

@media screen and #{breakpoint(medium)} {
	.hero {
		.inner img {
			width: auto;
		}
	}
}

//Loops over $hero-bg in _variables.scss and creates files with mixin
@each $name, $filename in $hero-bg {
  .#{$name} {
	@include hero-image($filename: $filename);
	background-color: map-get($hero-bg-color, $name);
  }
}

/* @end Hero */