.profile-brief {
    margin: 64px 0;
    h3 {
        @include font-sizes(large);
        color: $brand;
    }
    li {
        font-weight: $main-bold;
        color: $brand;
        padding: 0;
        &::before {
            display: none;
        }
    }
}
.profile-section {
    padding-bottom: 60px;
}
.profile-year-heading {
    font-weight: $main-bold;
}