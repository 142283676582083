.team-bio {
    margin-bottom: 60px;
    .headshot {
        margin-bottom: 27px;
        img {
            margin: 0;
        }
    }
    .title-block {
        .name {
            font-weight: $main-bold;
            display: block;
        }
    }
}
.narrow-container:last-child > .team-bio:last-child {
    margin-bottom: 0;
}