/*------------------------------------*\
    @group Animated Hamburger icon
\*------------------------------------*/

.hamburger {
	width: 16px;
	height: 24px;
	font-size: 0;
	margin: 0;
	padding: 0;
	transition: background 0.3s;
	cursor: pointer;
	display: block;
	position: relative;
	overflow: hidden;
	&:focus {
		outline: none;
	}
	span {
		height: 2px;
		background: $gray;
		display: block;
		position: absolute;
		top: 10px;
		left: 0;
		right: 0;
		&::before,
		&::after {
			content: '';
			background-color: $gray;
			width: 100%;
			height: 2px;
			display: block;
			position: absolute;
			left: 0;
		}
		&::before {
			top: -5px;
		}
		&::after {
			bottom: -5px;
		}
	}

}

.hamburger-x {
	span {
		transition: background 0s 0.3s;
		&::before,
		&::after {
			transition-duration: 0.3s, 0.3s;
			transition-delay: 0.3s, 0s;
		}
		&::before {
			transition-property: top, transform;
		}
		&::after {
			transition-property: bottom, transform;
		}
	}
}

//Active state, add .active with JS
.hamburger-x.active {
	span {
		background: none;
		&::before {
			top: 0;
			transform: rotate(45deg);
		}
		&::after {
			bottom: 0;
			transform: rotate(-45deg);
		}
		&::before,
		&::after {
			background-color: $brand;
			transition-delay: 0s, 0.3s;
		}
	}

}

/* @end Animated Hamburger icon */