/*----------------------------------------------------------*\
    Main Content Section

    This file contains styles for the main content section,
    including sidebars.

\*----------------------------------------------------------*/

.page-content {
    background: $white;
    padding: 30px 0 30px;
    .narrow-container {
        max-width: $narrow-container;
        margin-left: auto;
    }
    .inner {
        @include xy-grid-container;
    }
    .narrow-bg-left {
        padding-left: calc(100% - 420px);
    }
}

@include breakpoint(medium) {
    .narrow-bg-left {
        background-image: url('https://vyarch.imgix.net/sections/bg_seed_1.jpg?auto=format');
        background-position: calc(50% - 210px) center;
        background-repeat: no-repeat;
        &.bg-seed-2 {
            background-image: url('https://vyarch.imgix.net/sections/bg_seed_2.jpg?auto=format');
        }
        &.bg-seed-3 {
            background-image: url('https://vyarch.imgix.net/sections/bg_seed_3.jpg?auto=format');
        }
    }
    @media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
    only screen and (-o-min-device-pixel-ratio: 13/10),
    only screen and (min-resolution: 120dpi) {
        .narrow-bg-left {
            background-image: url('https://vyarch.imgix.net/sections/bg_seed_1@2x.jpg?auto=format');
            background-size: 299px 246px;
            &.bg-seed-2 {
                background-image: url('https://vyarch.imgix.net/sections/bg_seed_2@2x.jpg?auto=format');
                background-size: 285px 212px;
            }
            &.bg-seed-3 {
                background-image: url('https://vyarch.imgix.net/sections/bg_seed_3@2x.jpg?auto=format');
                background-size: 215px 273px;
            }
        }
    }
}