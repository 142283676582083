.gallery {
	display: flex;
	justify-content: space-around;
	align-items: center;
	margin: 0 auto 80px;
}

.gallery-spacing {
	margin: 0 auto 80px;
}

.gallery-item {
	text-align: center;
	&.single {
		padding: 0;
	}
	img {
		width: auto;
		margin: 0;
	}
	figcaption {
		font-weight: $main-bold;
		@include font-sizes(large); 
		color: $brand;
		text-align: left;
		margin: 0 0 15px;
	}
}

.gallery.grid-2 {
	flex-wrap: wrap;
	.gallery-item {
		width: 100%;
		margin: 16px 0;
	}
}
.gallery.grid-2-3-1-3 {
	flex-wrap: wrap;
	.gallery-item {
		width: 100%;
		margin: 16px 0;
	}
}

@media screen and #{breakpoint(medium)} {
	.gallery.grid-2,
	.gallery.grid-2-3-1-3 {
		flex-wrap: nowrap;
		.gallery-item {
			width: auto;
			margin: 0;
			&:first-child {
				padding-right: 20px;
			}
			&:last-child {
				padding-left: 20px;
			}
		}
	}

}