/*----------------------------------------------------------*\
    Variables

    This file contains all (non-Foundation) globally-scoped
    variables used throughout the Sass files. We strongly
    encourage sticking to these variables instead of
    hard-coded values as often as possible.

\*----------------------------------------------------------*/

//Font families
$sans-serif-font: "Roboto Condensed", Impact, "Franklin Gothic Bold", sans-serif;
$serif-font: sans-serif;
$icon-font: 'StarterTemplate';
$heading-font: $sans-serif-font;
$main-font: $sans-serif-font;
$alt-font: $heading-font;

//Font weights
$main-light: 300;
$main-normal: 400;
$main-bold: 700;
$alt-light: $main-light;
$alt-normal: $main-normal;
$alt-bold: $main-bold;
$heading-normal: $alt-normal;
$heading-bold: $alt-bold;

//Font sizes
$fsize: (
	'x-small': 12px,
	'small': 15px,
	'default': 18px,
	'large': 21px,
	'x-large': 26px
);

$fsize-desktop: (
	'x-small': 14px,
	'small': 16px,
	'default': 20px,
	'large': 24px,
	'x-large': 29px
);

$line-heights: (
	'x-small': 1.5,
	'small': 1.3333,
	'default': 1.5,
	'large': 1.14,
	'x-large': 1.23
);

$line-heights-desktop: (
	'x-small': 1.5,
	'small': 1.5,
	'default': 1.5,
	'large': 1.21,
	'x-large': 1.1
);


//Container widths
$narrow-container: 420px;

//Styleguide colors
$brand:				 #222222;
$brand-accent:		 #865BBC;
$brand-alt:			 #8AAAA6;
$brand-alt-accent:	 #90C5A9;
$white:              #FFFFFF;
$black:              #000000;
$gray:               #999999;

$colors: (
	'brand':			 $brand,
	'brand-accent':		 $brand-accent,
	'brand-alt':		 $brand-alt,
	'brand-alt-accent':	 $brand-alt-accent,
	'white':             $white,
	'black':             $black,
	'gray':              $gray
);

//Gradients, for use on backgrounds only
$gradients: (
	'white': linear-gradient(180deg, rgba(231, 231, 231, 1) 0%, rgba(255, 255, 255, 1) 100%)
);

//Transparent background colors
$brand-trans:        rgba($brand,0.8);
$black-trans:        rgba($black,0.7);

// Main body color, use when adding this color elsewhere (form fields as an example)
$main-color: #595959;

//Link colors
$link-base: #595959;
$link-active: $brand;

//Social media brand colors
$social: (
	'twitter': 		 #00aced,
	'facebook': 	 #3b5998,
	'googleplus': 	 #dd4b39,
	'pinterest': 	 #cb2027,
	'linkedin': 	 #007bb6,
	'youtube': 		 #bb0000,
	'vimeo': 		 #aad450,
	'instagram': 	 #517fa4,
	'quora': 		 #a82400,
	'foursquare': 	 #0072b1,
	'stumbleupon': 	 #EB4823,
	'soundcloud': 	 #ff3a00
);

//Shadows
$text-shadow: 1px 0 1px rgba(0,0,0,0.65);
$text-shadow-light: 1px 1px 1px rgb(255,255,255);
$box-shadow: 0 1px 1px rgba(0,0,0,1);

//Rounded corners
$border-round: 1000px;
$border-radius: 4px;

//Section spacing
$full-section-spacing-small: 		30px;
$content-section-spacing-small: 	60px;
$content-sub-section-spacing-small: 30px;
$intro-section-spacing-small: 		30px;
$full-section-spacing: 				60px;
$content-section-spacing: 			90px;
$content-sub-section-spacing: 		60px;
$intro-section-spacing: 			30px;

//Hero backgrounds
$hero-bg: (
	'home-orange': test
);

$hero-bg-color: (
	'home-orange': #ff9900
);