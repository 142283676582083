/*----------------------------------------------------------*\
    Slider

    All styles for sliders uses throughout the site are in
    this file.

\*----------------------------------------------------------*/
@import "node_modules/slick-carousel/slick/slick.scss";

/*------------------------------------*\
    @group Hero Slider
\*------------------------------------*/

.hero .hero-slider {
	width: 100%;
	font-size: 0;
	padding: 0;
	margin: 0;
	position: relative;
	overflow: hidden;
	img {
		width: auto;
		margin: 0;
		display: block;
	}
}

.slide {
	width: 100%;
}

/* @end */