/*------------------------------------*\
    @group Home Template
\*------------------------------------*/

.home-template .page-content .main-content {
	width: 100%;
}
.home-template .video-space .column {
	margin-bottom: 25px;
}
.home-template .video-highlight {
	img {
		margin: 0;
	}
}
.home-template .financed-space {
	background: #979797;
	font-family: $heading-font;
	text-align: center;
	padding: 30px 15px;
	h2 {
		font-size: rem-calc(32);
		color: #8C133F;
		line-height: 1;
		margin: 0;
		span {
			font-size: rem-calc(24);
		}
	}
	p {
		font-weight: bold;
		color: $white;
		line-height: 1.2;
		margin: 0 0 15px;
	}
}

@media screen and #{breakpoint(medium)} {
	.home-template .financed-space {
		padding: 66px 20px;
		h2 {
			font-size: rem-calc(54);
			span {
				font-size: rem-calc(36);
			}
		}
	}
}

/* @end Home Template */