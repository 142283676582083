/*----------------------------------------------------------*\
    Page Footer Section

    This file contains all styles for the footer section.

\*----------------------------------------------------------*/


.page-footer {
    background-color: $white;
    width: 100%;
    font-size: 14px;
    color: $gray;
    padding: 15px 0 15px;
    position: fixed;
    bottom: 0;
    z-index: 200;
    .inner {
        @include xy-grid-container;
		@include xy-grid;
		justify-content: space-between;
		align-items: center;
    }
    .social {
        display: flex;
        a {
            color: $gray;
            margin-left: 20px;
            &:hover, &:focus {
                color: $brand;
            }
        }
        svg {
            width: 25px;
            height: 25px;
        }
    }
}
