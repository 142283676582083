/*----------------------------------------------------------*\
    Header

    All styles for the main page header are below. This
    includes the navigation elements as well.

\*----------------------------------------------------------*/


.page-header {
	background-color: $white;
	width: 100%;
	padding: 16px 0;
	position: fixed;
	top: 0;
	z-index: 200;
	.inner {
		@include xy-grid-container;
		@include xy-grid;
		justify-content: space-between;
		align-items: flex-end;
	}
	.logo {
		img {
			margin: 0;
		}
	}
    .nav-toggle {
		font-size: rem-calc(16);
    	font-family: $alt-font;
    	font-weight: $alt-bold;
    	text-transform: uppercase;
    	display: flex;
    	align-items: center;
    	a {
    		color: #666;
    		padding-right: 8px;
    		&:hover, &:focus {
    			color: $brand;
    		}
    	}
    	&:hover a, &:focus a {
    		color: $brand;
    	}
    	&.active a {
    		display: none;
    	}
    }
    .main-nav {
    	display: none;
    }
}

body.nav-open {
	overflow: hidden;
}
.overlay-nav {
	background-color: rgba(0,0,0, .9);
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	z-index: 500;
	display: none;
	.selected > a {
		border-bottom: solid 2px $white;
		padding-bottom: 1px;
	}
	a {
		color: $white;
		&:hover, &:focus {
			color: #a6a6a6;
		}
	}
	> ul {
		font-size: 24px;
		padding: 20px;
		margin-top: 0;
		li {
			margin-bottom: 10px;
		}
		ul {
			font-size: 20px;
			li:first-child {
				padding-left: 34px;
				position: relative;
				&::before {
					content: '';
					width: 13px;
					height: 13px;
					display: block;
					border-left: solid 1px white;
					border-bottom: solid 1px white;
					position: absolute;
					top: 4px;
					left: 13px;
				}
			}
		}
	}
	.logo {
		padding: 16px 11px 0;
		img {
			margin: 0;
		}
	}
	.arrow {
		display: none;
	}
	.close {
		font-size: 40px;
		color: $white;
		cursor: pointer;
		position: absolute;
		right: 20px;
		top: 20px;
		span {
			height: 20px;
			width: 20px;
			font-size: 0;
			display: block;
			position: relative;
			&::before {
				content: '';
				background-color: #FFFFFF;
				width: 4px;
				height: 20px;
				transform: rotate(45deg);
				display: block;
				position: absolute;
				top: 0;
				left: 0;
			}
			&::after {
				content: '';
				background-color: #FFFFFF;
				width: 4px;
				height: 20px;
				transform: rotate(-45deg);
				display: block;
				position: absolute;
				top: 0;
				left: 0;
			}
		}
	}
}

@media screen and #{breakpoint(650px)} {
	.page-header {
		padding: 30px 0;
		.overlay-nav,
		.nav-toggle {
			display: none;
		}
	    .main-nav {
	    	display: block;
	    }
	}
}


/*------------------------------------*\
    @group Navigation
\*------------------------------------*/

.main-nav {
	width: 100%;
	max-width: $narrow-container;
	a {
		font-size: rem-calc(16);
		color: $gray;
		text-transform: uppercase;
		display: block;
		&:hover, &:focus {
			color: $brand;
		}
	}
	.selected > a {
		color: $black;
	}
	> ul {
		text-align: right;
		line-height: 1;
		> li {
			line-height: 1;
			display: inline-block;
		}
	}
	.list {
		background-color: $white;
		width: 200px;
		padding: 12px 10px 5px 16px;
		text-align: left;
		position: absolute;
		top: 100%;
		left: -16px;
		opacity: 0;
		visibility: hidden;
		transition: opacity .2s, visibility .2s;
		li {
			padding: 0;
		}
	}
	.dropdown {
		.arrow {
			padding-left: 5px;
		}
		svg {
			width: 11px;
			height: 11px;
			vertical-align: middle;
		}
	}
	.dropdown:hover {
		> a {
			color: $brand;
		} 
		.list {
			opacity: 1;
			visibility: visible;
		}
	}
}

@include breakpoint(medium) {
	.main-nav {
		> ul {
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
			> li {
				padding: 0;
			}
		}
	}
}
/* @end Navigation */