.work-feature-description {
    margin-bottom: 40px;
    .work-feature-sidebar {
        font-size: rem-calc(16);
        margin-bottom: 30px;
        h3 {
            font-size: rem-calc(18);
        }
        ul {
            margin-top: 0;
        }
        li {
            line-height: 1.2;
            padding: 0;
            margin: 0 0 10px;
            &:before {
                display: none;
            }
        }
        .sidebar-box {
            margin-bottom: 30px;
        }
    }
    .work-feature-content {
        max-width: $narrow-container;
        margin-left: auto;
    }
}
.work-feature .gallery:last-child {
    margin-bottom: 0;
}

@include breakpoint(750px) {
    .work-feature-description {
        display: flex;
        .work-feature-sidebar {
            max-width: 210px;
            margin: 0;
        }
    }
}

// Poem
.salt-poem {
    line-height: 1.1;
    margin-bottom: 60px;
}