.passive-house-feature {
    margin-bottom: 30px;
    @include breakpoint(medium) {
    margin-bottom: 60px;
    }
}

.phius-logo {
    img {
        margin-right: 15px;
    }
}