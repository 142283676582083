/*----------------------------------------------------------*\
   Blockquote

   Blockquote styling with large quote image. Background
   color can be overriden with the background (and gradient)
   classes listed on the styleguide.

\*----------------------------------------------------------*/


blockquote {
	margin: 20px 0;
	cite {
		font-style: normal;
		line-height: 1.1;
		text-align: right;
		.speaker {
			color: $brand-accent;
			font-weight: 700;
		}
		&::before {
			content: '';
		}
	}
	.quote-wrap {
		padding: 15px 15px 0;
		border: solid 1px $light-gray;
		border-radius: $border-radius;
		box-shadow: $box-shadow;
		margin: 0 0 10px;
	}
}