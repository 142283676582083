.hover-gallery {
    .hover-gallery-item {
        max-width: 540px;
        position: relative;
        margin: 0 auto 30px;
    }
    .title {
        background-color: rgba(255, 255, 255, .8);
        width: 100%;
        height: 100%;
        font-size: rem-calc(18);
        font-weight: $main-normal;
        color: $brand;
        text-transform: uppercase;
        text-align: right;
        line-height: 1.2;
        padding: 0 15px;
        position: absolute;
        opacity: 0;
        visibility: hidden;
        top: 0;
        left: 0;
        transition: opacity .2s, visibility .2s;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        span {
            transform: translateY(20px);
            transition: transform .3s;
        }
    }
    .hover-gallery-item:hover .title, .hover-gallery-item:focus .title {
        opacity: 1;
        visibility: visible;
        span {
            transform: translateY(0);
        }
    } 
    img {
        margin: 0;
    }
}

@include breakpoint(medium) {
    .hover-gallery {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        .hover-gallery-item {
            width: 47%;
            margin: 0 0 3%;
        }
    }
}

@include breakpoint(900px) {
    .hover-gallery {
        .hover-gallery-item {
            width: 31.333%;
            margin: 0 0 3%;
        }
    }
}