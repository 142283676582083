/*----------------------------------------------------------*\
    Forms

    The majority of the form styling comes from the
    Foundation settings file. Any new forms added that
    need specific styling (like search forms as seen here)
    should be added in this file.

\*----------------------------------------------------------*/


form .req {
	color: $brand-alt-accent;
}
form .button {
	background: $brand-alt;
	&:hover,
	&:focus {
		background: $brand-alt-accent;
	}
	.icon {
		vertical-align: 2px;
	}
	&.pre-icon .icon {
		margin-right: 5px;
	}
}