/*----------------------------------------------------------*\
    Mixins

    This file contains custom mixins. All mixins should go
    here as this file is imported into both the base and
    main css files.

\*----------------------------------------------------------*/

 
/*------------------------------------*\
    @group Social Media Link Colors
\*------------------------------------*/

@mixin link-colors ($color: null, $bg: null) {
	background-color: $bg;
	color: $color;
	&:hover, &:focus {
		@if ($bg) {
			background-color: darken($bg,10%);
		}
		@if ($color and $bg == null) {
			//color: darken($color,10%);
		}
	}
}

/* @end Social Media Link Colors */

/*------------------------------------*\
    @group Hero image Sizes
\*------------------------------------*/
@mixin hero-image($filename,$extension: jpg) {
  background-image: url('/images/hero/bg_' + $filename + '.' + $extension);
  @media screen and #{breakpoint(medium)} {
    & {
      background-image: url('/images/hero/bg_' + $filename + '_med.' + $extension);
    }
  }
  @media screen and #{breakpoint(large)} {
	& {
	  background-image: url('/images/hero/bg_' + $filename + '_lrg.' + $extension);
	}
  }
}

/* @end Hero Images */

 @mixin font-sizes($key) {
  font-size: rem-calc(map-get($fsize, $key));
  line-height: map-get($line-heights, $key);
  @include breakpoint(medium) {
    font-size: rem-calc(map-get($fsize-desktop, $key));
    line-height: map-get($line-heights-desktop, $key);
  }
}